import { default as _91all_93zqssJHFIaNMeta } from "/opt/build/repo/pages/[all].vue?macro=true";
import { default as AboutuDxQyq8rxRMeta } from "/opt/build/repo/pages/About.vue?macro=true";
import { default as CalendarHL8GGT2UTvMeta } from "/opt/build/repo/pages/Calendar.vue?macro=true";
import { default as ContactwWwo5eBi4HMeta } from "/opt/build/repo/pages/Contact.vue?macro=true";
import { default as bookingsnFRabeyRnLMeta } from "/opt/build/repo/pages/Dashboard/bookings.vue?macro=true";
import { default as indexulGeAvWUR0Meta } from "/opt/build/repo/pages/Dashboard/index.vue?macro=true";
import { default as _91slug_933f1MlZrGWMMeta } from "/opt/build/repo/pages/Events/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as Join6GdeVu3CO0Meta } from "/opt/build/repo/pages/Join.vue?macro=true";
import { default as Login9fL5qKeXxUMeta } from "/opt/build/repo/pages/Login.vue?macro=true";
import { default as _91slug_93y9HNJzG32XMeta } from "/opt/build/repo/pages/Policies/[slug].vue?macro=true";
import { default as indexR2rLDCDVsMMeta } from "/opt/build/repo/pages/Policies/index.vue?macro=true";
import { default as _91slug_93dJMo6wYtABMeta } from "/opt/build/repo/pages/Rentals/[slug].vue?macro=true";
import { default as accept_45estimateWvh81soj9dMeta } from "/opt/build/repo/pages/Rentals/accept-estimate.vue?macro=true";
import { default as estimate_45acceptedEkZQZtaU1YMeta } from "/opt/build/repo/pages/Rentals/estimate-accepted.vue?macro=true";
import { default as indexOZpGkM1bHnMeta } from "/opt/build/repo/pages/Rentals/index.vue?macro=true";
import { default as testXMry6JwDrdMeta } from "/opt/build/repo/pages/Rentals/test.vue?macro=true";
import { default as _91slug_93aUaJvAMQU7Meta } from "/opt/build/repo/pages/Residencies/[slug].vue?macro=true";
import { default as indexP5RblvZwBmMeta } from "/opt/build/repo/pages/Residencies/index.vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all()",
    meta: _91all_93zqssJHFIaNMeta || {},
    component: () => import("/opt/build/repo/pages/[all].vue")
  },
  {
    name: "About",
    path: "/About",
    component: () => import("/opt/build/repo/pages/About.vue")
  },
  {
    name: "Calendar",
    path: "/Calendar",
    component: () => import("/opt/build/repo/pages/Calendar.vue")
  },
  {
    name: "Contact",
    path: "/Contact",
    component: () => import("/opt/build/repo/pages/Contact.vue")
  },
  {
    name: "Dashboard-bookings",
    path: "/Dashboard/bookings",
    meta: bookingsnFRabeyRnLMeta || {},
    component: () => import("/opt/build/repo/pages/Dashboard/bookings.vue")
  },
  {
    name: "Dashboard",
    path: "/Dashboard",
    meta: indexulGeAvWUR0Meta || {},
    component: () => import("/opt/build/repo/pages/Dashboard/index.vue")
  },
  {
    name: "Events-slug",
    path: "/Events/:slug()",
    component: () => import("/opt/build/repo/pages/Events/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "Join",
    path: "/Join",
    component: () => import("/opt/build/repo/pages/Join.vue")
  },
  {
    name: "Login",
    path: "/Login",
    meta: Login9fL5qKeXxUMeta || {},
    component: () => import("/opt/build/repo/pages/Login.vue")
  },
  {
    name: "Policies-slug",
    path: "/Policies/:slug()",
    component: () => import("/opt/build/repo/pages/Policies/[slug].vue")
  },
  {
    name: "Policies",
    path: "/Policies",
    component: () => import("/opt/build/repo/pages/Policies/index.vue")
  },
  {
    name: "Rentals-slug",
    path: "/Rentals/:slug()",
    component: () => import("/opt/build/repo/pages/Rentals/[slug].vue")
  },
  {
    name: "Rentals-accept-estimate",
    path: "/Rentals/accept-estimate",
    component: () => import("/opt/build/repo/pages/Rentals/accept-estimate.vue")
  },
  {
    name: "Rentals-estimate-accepted",
    path: "/Rentals/estimate-accepted",
    component: () => import("/opt/build/repo/pages/Rentals/estimate-accepted.vue")
  },
  {
    name: "Rentals",
    path: "/Rentals",
    component: () => import("/opt/build/repo/pages/Rentals/index.vue")
  },
  {
    name: "Rentals-test",
    path: "/Rentals/test",
    component: () => import("/opt/build/repo/pages/Rentals/test.vue")
  },
  {
    name: "Residencies-slug",
    path: "/Residencies/:slug()",
    component: () => import("/opt/build/repo/pages/Residencies/[slug].vue")
  },
  {
    name: "Residencies",
    path: "/Residencies",
    component: () => import("/opt/build/repo/pages/Residencies/index.vue")
  }
]