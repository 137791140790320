<template>
  <!-- Page Heading -->
  <!-- todo: you are already logged in! -->
  <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
    <template #header v-if="route.fullPath !== '/login'">
      Login to your Tranzac account
    </template>

    <!-- Feedback Message -->
    <div v-if="loginMessage" :class="`p-4 rounded-md mb-4 ${loginMessageClass}`">
      {{ loginMessage }}
    </div>

    <!-- Login Form -->
    <UForm v-if="!linkSent" :state="loginState" @submit="handleLogin" class="w-full space-y-6">
      <!-- Email Input -->
      <UFormGroup name="email" :ui="{
        label: {
          base: 'text-stone-700 dark:text-stone-100 text-base font-semibold',
        }
      }">
        <UInput v-model="loginState.email" type="email" placeholder="Enter your email" size="lg" :ui="{
          color: {
            white: {
              outline: 'dark:bg-stone-100 text-stone-900 dark:text-stone-800 ring-1 ring-inset ring-stone-300 dark:ring-stone-300 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400',
            },
          },
        }" />
      </UFormGroup>

      <!-- Submit Button -->
      <div class="flex justify-center mt-4">
        <UButton type="submit" class="rounded-full" color="primary" variant="solid" size="lg" :loading="isLoading">
          Send Login Link
        </UButton>
      </div>
    </UForm>

    <!-- Close Button -->
    <template #footer v-if="linkSent">
      <div class="flex justify-center">
        <UButton @click="$emit('close')" class="rounded-full" color="primary" variant="solid" size="lg">
          Close
        </UButton>
      </div>
    </template>
  </UCard>
</template>



<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import type { FormSubmitEvent } from '#ui/types';

const route = useRoute(); // Get access to route details
const loginState = ref({
  email: '',
  redirectUrl: '', // Initialize redirectUrl in the form state
});
const loginMessage = ref('');
const loginMessageClass = ref('');
const isLoading = ref(false);
const linkSent = ref(false); // New state to track if the link has been sent

// On mount, set redirectUrl to the path with query and hash from useRoute
onMounted(() => {
  const fullPath = route.fullPath; // Captures path, query params, and hash
  loginState.value.redirectUrl = fullPath; // Use relative path for redirectUrl
  console.log('redirectUrl:', loginState.value.redirectUrl);
});

async function handleLogin(event: FormSubmitEvent<{ email: string }>) {
  loginMessage.value = ''
  loginMessageClass.value = ''
  isLoading.value = true
  try {
    const response = await $fetch('/api/members/send-login-link', {
      method: 'POST',
      body: {
        email: event.data.email,
        redirectUrl: loginState.value.redirectUrl // Pass the captured redirectUrl
      }
    })
    loginMessage.value = response.message
    loginMessageClass.value = 'bg-green-100 text-green-800 border border-green-300'
    linkSent.value = true // Set linkSent to true after successful submission
    loginState.value.email = ''
  } catch (error) {
    console.error('Error sending login link:', error)
    if (error.statusCode === 404) {
      loginMessage.value = 'No account found with this email. You can create an account by joining the Tranzac or making a rental request.'
    } else if (error.statusCode === 400) {
      loginMessage.value = 'Your login link has expired. Please contact support.'
    } else {
      loginMessage.value = 'Failed to send login link. Please try again.'
    }
    loginMessageClass.value = 'bg-red-100 text-red-800 border border-red-300'
  } finally {
    isLoading.value = false
  }
}
</script>
