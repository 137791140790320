<template>
  <div>
    <UModal v-model="loginModalStore.isOpen" :ui="{
      container: 'items-center',
    }">
      <LoginForm @close="loginModalStore.close" />
    </UModal>
  </div>
</template>

<script setup lang="ts">
import { useLoginModalStore } from '@/stores/loginModalStore';

// Access the store
const loginModalStore = useLoginModalStore();
</script>
