import { defineStore, createPinia, setActivePinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export default { store: setActivePinia(pinia) };
export const useUserStore = defineStore("user", {
  state: () => ({
    userInfo: null,
    loginToken: null,
    stripeCustomerPortalUrl: null,
  }),
  actions: {
    async login(token) {
      try {
        const response = await $fetch("/api/login", {
          method: "POST",
          body: { token },
        });
        if (response.success) {
          this.setUser(response.user);
          return true;
        }
      } catch (error) {
        console.error("Login error:", error);
      }
      return false;
    },
    async fetchUserProfile() {
      try {
        const requestFetch = useRequestFetch();
        const response = await $fetch("/api/auth/me");
        console.log("Fetched profile response:", response); // Ensure it contains the full profile
        this.setUser(response); // Update userInfo with full profile data
        return true;
      } catch (error) {
        console.error("Error fetching user profile in userStore:", error);
        this.clearUser();
        return false;
      }
    },
    setUser(userData) {
      console.log("Setting user data:", userData);
      this.userInfo = userData;
    },
    updatePaymentInfo(paymentInfo) {
      if (this.userInfo && this.userInfo.membership) {
        this.userInfo.membership = {
          ...this.userInfo.membership,
          ...paymentInfo,
        };
      }
    },
    clearUser() {
      this.userInfo = null;
      this.stripeCustomerPortalUrl = null;
    },
    async fetchStripeCustomerPortalUrl() {
      if (
        !this.userInfo?.id ||
        this.userInfo?.membership.paymentMethod !== "stripe"
      ) {
        console.log("User not eligible for Stripe customer portal");
        return;
      }
      try {
        const response = await $fetch("/api/stripe-create-portal", {
          method: "POST",
          body: { customerId: this.userInfo.membership.stripeCustomerId },
        });
        this.stripeCustomerPortalUrl = response.url;
        console.log(
          "Stripe customer portal URL set:",
          this.stripeCustomerPortalUrl
        );
      } catch (error) {
        console.error("Error fetching Stripe customer portal URL:", error);
      }
    },

    async logout() {
      try {
        await $fetch("/api/logout", { method: "POST" });
        this.clearUser();
        return true;
      } catch (error) {
        console.error("Logout error:", error);
        return false;
      }
    },

    clearStripeCustomerPortalUrl() {
      this.stripeCustomerPortalUrl = null;
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.userInfo,
    fullName: (state) =>
      state.userInfo
        ? `${state.userInfo.firstName} ${state.userInfo.lastName}`
        : "",

    isActiveMember: (state) => state.userInfo?.membership?.status === "active",
    paymentMethod: (state) => state.userInfo?.membership?.paymentMethod,
    paymentType: (state) => state.userInfo?.membership?.paymentType,
    lastPaymentDate: (state) => state.userInfo?.membership?.lastPaymentDate,
    lastPaymentAmount: (state) => state.userInfo?.membership?.lastPaymentAmount,
    getUserId: (state) => state.userInfo?.id,
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
