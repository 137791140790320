import { useUserStore } from "@/stores/userStore";

export function useAuth() {
  const userStore = useUserStore();

  async function register(userData) {
    console.log("Registering user:", userData);
    try {
      const response = await $fetch("/api/members/register", {
        method: "POST",
        body: userData,
      });

      console.log("Registration response:", response); // Log response for debugging

      if (response.registered && response.user && response.token) {
        console.log("Before setting user:", userStore.userInfo);
        userStore.setUser(response.user); // Ensure this updates the store correctly
        console.log("After setting user:", userStore.userInfo);
        return true;
      } else {
        console.error("Invalid registration response:", response);
        throw new Error("Registration failed: Invalid response data");
      }
    } catch (error) {
      console.error("Registration error:", error);
      throw error;
    }
  }

  async function logout() {
    try {
      // Invalidate the session on the server
      await $fetch("/api/auth/logout", { method: "DELETE" });

      // Clear the user data from the store
      userStore.clearUser();
    } catch (error) {
      console.error("Logout error:", error);
      // Clear the local user data even if the server call fails
      userStore.clearUser();
    }
  }

  async function checkAuth() {
    // Always attempt to fetch the user profile, let userStore handle the logic
    return await userStore.fetchUserProfile();
  }

  async function sendLoginLink(email: string) {
    try {
      const response = await $fetch("/api/send-login-link", {
        method: "POST",
        body: { email },
      });
      return response.message; // Assuming the API returns a success message
    } catch (error) {
      console.error("Error sending login link:", error);
      throw new Error("Failed to send login link");
    }
  }

  return {
    checkAuth,
    user: computed(() => userStore.userInfo),
    loggedIn: computed(() => userStore.isLoggedIn),
    register,
    logout,
    sendLoginLink,
  };
}
