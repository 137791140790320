<template>
  <div>
    <NuxtLayout />
    <UNotifications />
    <LoginModal />
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useAuth } from "@/composables/useAuth";
import { useUserStore } from "@/stores/userStore";
const route = useRoute();

// State setup
const userStore = useUserStore();
const { checkAuth, loggedIn } = useAuth();

// Favicon and SEO query
const QUERY = `query {
  site: _site {
    noIndex
    globalSeo {
      twitterAccount
      titleSuffix
      siteName
      fallbackSeo {
        twitterCard
        title
        noIndex
        image {
          url
        }
        description
      }
      facebookPageUrl
    }
    favicon {
      url
    }
    faviconMetaTags {
      attributes
    }
  }
}`;

// Function to fetch SEO data using useAsyncData
const { data: datoData, error } = await useAsyncData("seoData", async () => {
  try {
    // console.log("Fetching SEO data...");
    const response = await useGraphqlQuery({ query: QUERY });
    const result = response.data.value; // Unwrap the nested Ref
    // console.log("GraphQL response received:", result);
    return result;
  } catch (err) {
    // console.error("Error fetching SEO data:", err);
    throw err;
  }
});

// Initialize user authentication
const initializeUser = async () => {
  try {
    const isAuthenticated = await userStore.fetchUserProfile();
  } catch (error) {
    console.error("Error fetching user profile:", error);
  }
};

onMounted(async () => {
  await initializeUser();
});

watchEffect(() => {
  const fullUrl = `${useRuntimeConfig().BASE_URL}${route.fullPath}`;

  if (datoData.value) {
    const siteData = datoData.value.site;

    if (siteData) {
      // console.log("Setting SEO with data:", siteData);

      useSeoMeta({
        title: siteData.globalSeo?.fallbackSeo?.title || "",
        titleTemplate: `%s ${siteData.globalSeo?.titleSuffix || ""}`,
        description: siteData.globalSeo?.fallbackSeo?.description || "",
        meta: [
          { name: "description", content: siteData.globalSeo?.fallbackSeo?.description || "" },
          { name: "twitter:card", content: siteData.globalSeo?.fallbackSeo?.twitterCard || "summary" },
          { name: "twitter:site", content: siteData.globalSeo?.twitterAccount || "" },
          { name: "robots", content: siteData.noIndex ? "noindex, nofollow" : "index, follow" },
          { property: "og:site_name", content: siteData.globalSeo?.siteName || "" },
          { property: "og:title", content: siteData.globalSeo?.fallbackSeo?.title || "" },
          { property: "og:url", content: fullUrl || "https://tranzac.org" },
          { property: "og:description", content: siteData.globalSeo?.fallbackSeo?.description || "" },
          { property: "og:image", content: siteData.globalSeo?.fallbackSeo?.image?.url || "" },
        ],
        link: [
          {
            rel: "icon",
            type: siteData.faviconMetaTags?.[0]?.attributes?.type || "image/png",
            href: siteData.favicon?.url || "/favicon.png",
          },
          {
            rel: 'canonical',
            href: fullUrl,
          },
        ],
      });
    } else {
      console.log("SEO data not available - siteData is missing.");
    }
  }
});
</script>

<style>
body {
  @apply min-h-screen;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
