export default defineNuxtPlugin((nuxtApp) => {
  const colorMode = useColorMode();

  if (colorMode.value === "light") {
    colorMode.value = "dark";
  }

  watch(colorMode, (newMode) => {
    if (newMode === "light") {
      colorMode.value = "dark";
    }
  });
});
